.die-btn {
    cursor: pointer;
    font-family: inherit;
    font-size: 3rem;
    background: aquamarine;
    width: 100%;
    padding: 1rem;
    /* border-top: 8px solid #333; */
    cursor: pointer;
}

.die {
    font-size: 8rem;
}

.die-wrapper {
    width: 100%;
    border-top: 8px solid #333;
    display: flex;
    background: coral;
    justify-content: space-evenly;
}